<template>
  <div class="row ml-2 mr-2">
    <deadline-alert :isDeadlineReached="isDeadlineReached()" />
    <div class="col-12 col-lg-6">
      <base-input label="Nome completo" v-model="orderCurrent.Customer.Name" @input="changeProperty('Name', $event)"
        :readonly="isReadonlyData()"></base-input>
    </div>
    <div class="col-12 col-lg-6">
      <base-input label="Email" v-model="orderCurrent.Customer.Email" :readonly="isReadonlyData()"></base-input>
    </div>
    <div class="col-12 col-lg-3">
      <base-input label="Documento" :readonly="isReadonlyData()" v-model="orderCurrent.Customer.TaxIdentification">
        <template v-slot:helperText
          v-if="orderCurrent.Customer.Reseller && orderCurrent.Customer.Reseller.FilledByNuuk">
          <p style="color: green"><i style="color: green" class="el-icon-circle-check"></i> Validado no SEFAZ</p>
        </template>
      </base-input>
    </div>
    <div class="col-3">
      <base-input label="Telefone" v-model="orderCurrent.Customer.PhoneNumber" :readonly="isReadonlyData()" />
    </div>
    <div class="col-2" v-if="orderCurrent.Customer.Reseller">
      <base-input label="Inscrição Estadual" v-model="orderCurrent.Customer.Reseller.StateRegistry"
        :readonly="isReadonlyData()"></base-input>
    </div>
    <div class="col-1" v-if="orderCurrent.Customer.Reseller">
      <input style="margin-top: 50px" type="checkbox" v-model="isTaxFree" @change="checkTaxFree" />
      <label style="margin: 0px 0px 0px 5px"> ISENTO</label>
    </div>
    <div class="col-3">
      <base-input label="Grupo do cliente" v-model="orderCurrent.Customer.Role" :readonly="isReadonlyData()" />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import constants from "@/util/constants";
import deadlineAlert from "@/components/AnonymizationDeadlineAlert.vue";

export default {
  components: {
    deadlineAlert
  },
  props: {
    isTaxFree: { type: false },
    stateRegistryTemp: { type: String },
    useNuuk: { type: Object }
  },
  computed: {
    ...mapState("orders", ["orderCurrent"]),
    ...mapState("health", ["displayRestrictedData"])
  },
  methods: {
    ...mapMutations("orders", ["UPDATE_PROPERTY_ORDER_CUSTOMER"]),
    changeProperty(property, newValue) {
      this.UPDATE_PROPERTY_ORDER_CUSTOMER({ property: property, newValue: newValue });
    },
    checkTaxFree() {
      if (this.isTaxFree) {
        this.Order.Customer.Reseller.StateRegistry = "Isento";
        return;
      }
      this.Order.Customer.Reseller.StateRegistry = this.stateRegistryTemp;
    },
    shouldDisable() {
      return this.isTaxFree;
    },
    isReadonlyData() {
      return !this.displayRestrictedData || this.orderCurrent.Anonymized;
    },
    isDeadlineReached() {
      return this.displayRestrictedData && this.orderCurrent.Anonymized;
    }
  },
};
</script>
